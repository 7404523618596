import React, { useState, useMemo, useRef, useCallback } from 'react';
import CustomSelect from '../components/CustomSelect';
import type { TrendDataProps, TimeFrameItemProps } from '../../../types';
import { timeFrameSelect } from '../../../config';
import styles from './index.module.less';

interface Props {
    onChange?: (a?: TrendDataProps) => void;
}

const TrendFilter: React.FC<Props> = ({ onChange }) => {
    const trendRef = useRef<any>(null);
    const [timeFrame, setTimeFrame] = useState<string | undefined>(undefined);
    const [opinionIntraday, setOpinionIntraday] = useState<string | undefined>(undefined);

    const newTrend: any = useMemo(() => {
        return (timeFrameSelect || []).find((item: TimeFrameItemProps) => item.value === timeFrame) || {};
    }, [timeFrame]);

    /**
     * 时间范围
     * @param v
     */
    const onTimeFrameChange = useCallback(
        (v: string) => {
            const value = v === 'ALL' ? undefined : v;
            setTimeFrame(value);
            setOpinionIntraday(undefined);
            onChange?.({
                time_frame: value,
                [newTrend.prop]: undefined,
            });
        },
        [newTrend.prop, onChange],
    );

    /**
     * 趋势
     * @param v
     */
    const onOpinionIntradayChange = useCallback(
        (v: string) => {
            setOpinionIntraday(v);

            onChange?.({
                time_frame: timeFrame,
                [newTrend.prop]: v,
            });
        },
        [onChange, timeFrame, newTrend],
    );

    const selectList = useMemo(() => {
        return [
            {
                key: 'timeFrame',
                value: timeFrame,
                options: timeFrameSelect,
                className: styles.timeFrameSelect,
                getPopupContainer: () => trendRef?.current,
                onChange: onTimeFrameChange,
                style: { width: 100, marginRight: 8 },
            },
            {
                key: 'opinionIntraday',
                value: opinionIntraday,
                options: newTrend.children,
                className: styles.opinionIntradaySelect,
                getPopupContainer: () => trendRef?.current,
                onChange: onOpinionIntradayChange,
                style: { width: 160 },
            },
        ];
    }, [opinionIntraday, timeFrame, newTrend.children, onOpinionIntradayChange, onTimeFrameChange]);

    return (
        <div className={styles.trendFilter} ref={trendRef}>
            {selectList.map((item) => (
                <CustomSelect {...item} key={item.key} />
            ))}
        </div>
    );
};

export default TrendFilter;
