import React, { useState, useEffect, useMemo, useCallback } from 'react';
import DropPicker from '../../DropPicker';
import DatePicker from 'react-mobile-datepicker';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import c from 'classnames';
import { cloneDeep } from 'lodash';
import { timerFilters, dateConfig } from '../../../config';
import styles from './index.module.less';
import intl from 'react-intl-universal';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';

interface Props {
    inSearch?: boolean;
    onChange?: (a: string[] | null | undefined) => void;
}

const SymbolFilter: React.FC<Props> = ({ onChange, inSearch }) => {
    const currentLocale = intl.getInitOptions().currentLocale;

    const [activeKey, setActiveKey] = useState<string>('all');
    const [activeTimer, setActiveTimer] = useState<string[] | null>(null);

    /**
     * 时间选择
     */
    const [selectTimer, setSelectTimer] = useState<string[]>([]);
    const [pickerOpen, setPickerOpen] = useState<boolean>(false);
    const [activeTimeNum, setActiveTimeNum] = useState<number>(0);
    const [errorNum, setErrorNum] = useState<number>(-1);

    /**
     * 时间格式
     */
    const timerFormat = 'YYYY-MM-DD HH:mm';
    const showFormat = 'YYYY.MM.DD HH:mm';
    const pickerFormat = 'YYYY-MM-DD hh:mm';

    /**
     * 禁用/启用页面滚动
     */
    useEffect(() => {
        if (pickerOpen) {
            document.body.style.height = '100vh';
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.height = 'auto';
            document.body.style.overflow = 'auto';
        }
    }, [pickerOpen]);

    /**
     * 查询时间
     * @param value
     * @returns
     */
    const getTimeOfDayTransform = (value: any) => {
        if (value) {
            return [
                dayjs(value[0]).startOf('day').format(timerFormat),
                dayjs(value[1]).endOf('day').format(timerFormat),
            ];
        } else {
            return null;
        }
    };

    /**
     * 点击选择
     * @param v
     */
    const onSelectClick = useCallback((v: string, dates?: string[] | null) => {
        if (v !== 'timer') {
            setSelectTimer([]);
            setPickerOpen(false);
            setErrorNum(-1);
        }
        setActiveKey(v);
        switch (v) {
            case 'all':
                setActiveTimer(null);
                break;
            case 'today':
                const today = dayjs();
                const todaytimer = getTimeOfDayTransform([today, today]);
                setActiveTimer(todaytimer);
                break;
            case 'sevenDay':
                const sevenDayTimer = getTimeOfDayTransform([dayjs().add(-6, 'day'), dayjs()]);
                setActiveTimer(sevenDayTimer);
                break;
            case 'timer':
                setActiveTimer(dates || null);
                break;
            default:
                setActiveTimer(null);
                break;
        }
    }, []);

    /**
     * 时间选择
     */
    const onOpenPicker = (t?: string) => {
        switch (t) {
            case 'start':
                setActiveTimeNum(0);
                break;
            case 'end':
                setActiveTimeNum(1);
                break;
            default:
                setActiveTimeNum(0);
        }
        setErrorNum(-1);
        setActiveKey('timer');
        setPickerOpen(true);
    };

    /**
     * Date 时间
     */
    const timerValue = useMemo(() => {
        return selectTimer[activeTimeNum] ? new Date(selectTimer[activeTimeNum]) : new Date();
    }, [activeTimeNum, selectTimer]);

    /**
     * 时间规则
     */
    const timerDisable = useMemo(() => {
        switch (activeTimeNum) {
            case 0:
                if (selectTimer[1]) {
                    return { max: new Date(selectTimer[1]) };
                }
                break;
            case 1:
                if (selectTimer[0]) {
                    return { min: new Date(selectTimer[0]) };
                }
                break;
            default:
                return {};
        }
        return {};
    }, [activeTimeNum, selectTimer]);

    /**
     * name
     */
    const activeName = useMemo(() => {
        switch (activeKey) {
            case 'timer':
                return intl.get('Broker.Customize').d('自定义');
            case 'all':
                return '';
            default:
                const { label, alias } = timerFilters.find((item) => item.value === activeKey) || {};
                return intl.get(label || '').d(alias || '');
        }
    }, [activeKey, currentLocale]);

    /**
     * 时间变化
     * @param v
     */
    const onDateTimeChange = useCallback(
        (v: string) => {
            const dateForShow = cloneDeep(selectTimer || []);
            const dateForForm = cloneDeep(selectTimer || []);
            dateForShow[activeTimeNum] = dayjs(v).format(showFormat);
            dateForForm[activeTimeNum] = dayjs(v).format(timerFormat);
            setErrorNum(-1);
            setSelectTimer(dateForShow);
            onSelectClick('timer', dateForForm);
        },
        [activeTimeNum, onSelectClick, selectTimer],
    );

    /**
     * 时间选择器
     */
    const DatePickerMemo = useMemo(() => {
        return (
            <div>
                <DatePicker
                    isPopup={false}
                    showHeader={false}
                    showFooter={false}
                    value={timerValue}
                    isOpen={pickerOpen}
                    dateConfig={dateConfig}
                    headerFormat={pickerFormat}
                    onChange={onDateTimeChange}
                    locale={intl.getInitOptions().currentLocale === 'en' ? enUS : zhCN}
                    {...timerDisable}
                />
            </div>
        );
    }, [pickerOpen, timerDisable, timerValue, onDateTimeChange]);

    /**
     * 确定时间
     */
    const onConfirm = (_t: any, call: any) => {
        if (activeKey === 'timer') {
            if (!selectTimer) {
                setErrorNum(2);
                return;
            }
            if (!selectTimer[0]) {
                setErrorNum(0);
                return;
            }
            if (!selectTimer[1]) {
                setErrorNum(1);
                return;
            }
        }

        setErrorNum(-1);
        onChange?.(activeTimer);
        call?.(false);
    };

    return (
        <div className={styles.timerFilter}>
            <DropPicker
                name={activeName || intl.get('Analyze.TimeRange').d('时间范围')}
                dropkey="timer"
                onChange={onConfirm}
                defaultValue={['all']}
                callback={true}
                inSearch={inSearch}
                dropRender={() => {
                    return (
                        <div className={styles.dropTimer}>
                            <ul className={styles.timerList}>
                                {timerFilters.map((item) => {
                                    const { value, label, alias } = item;
                                    const active = value === activeKey;
                                    return value !== 'timer' ? (
                                        <li
                                            className={c(styles.filterItem, active ? styles.activeItem : null)}
                                            key={value || label}
                                            onClick={() => onSelectClick(value)}
                                        >
                                            {intl.get(label).d(alias)}
                                        </li>
                                    ) : null;
                                })}
                            </ul>

                            <div className={styles.timeSelect}>
                                <div
                                    className={c({
                                        [styles.timeItem]: true,
                                        [styles.activeItem]: pickerOpen && activeTimeNum === 0,
                                        [styles.errorItem]: errorNum === 2 || errorNum === 0,
                                    })}
                                    onClick={() => onOpenPicker('start')}
                                >
                                    {selectTimer[0] || intl.get('Analyze.StartDate').d('开始日期')}
                                </div>
                                <span className={styles.timeSplit} />
                                <div
                                    className={c({
                                        [styles.timeItem]: true,
                                        [styles.activeItem]: pickerOpen && activeTimeNum === 1,
                                        [styles.errorItem]: errorNum === 2 || errorNum === 1,
                                    })}
                                    onClick={() => onOpenPicker('end')}
                                >
                                    {selectTimer[1] || intl.get('Analyze.EndDate').d('结束日期')}
                                </div>
                            </div>

                            {pickerOpen && <div className={styles.datePicker}>{DatePickerMemo}</div>}
                        </div>
                    );
                }}
            />
        </div>
    );
};

export default SymbolFilter;
