import React, { useState } from 'react';
import dayjs from 'dayjs';
import { timeFrameSelect } from '../config';
import { NextImage } from '@/components';
import c from 'classnames';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    list: any[];
    defaultId?: string | number;
    loader?: React.ReactNode;
    onSelect?: (a: any) => void;
}

const AnalystList: React.FC<Props> = ({ list = [], defaultId, loader, onSelect }) => {
    const [activeId, setActiveId] = useState<string | number>(defaultId || '');

    /**
     * 获取时间范围
     * @param time_frame
     * @returns
     */
    const timeFrameItemFun = (time_frame: string) => {
        return timeFrameSelect.find((item: any) => item.value === time_frame) || ({} as any);
    };

    /**
     * 获取趋势
     * @param item
     * @param timeFrameItem
     * @returns
     */
    const optionsItemFun = (item: any, timeFrameItem: any) => {
        const { prop, optionsSelect = [] } = timeFrameItem || {};
        const val = item[prop] || '';

        return optionsSelect.find((item: any) => item.value === val) || ({} as any);
    };

    /**
     * 选择列表项
     * @param record
     */
    const onClickItem = (record: any) => {
        setActiveId(record?._id);
        onSelect?.(record);
    };

    return list.length !== 0 ? (
        <div className={styles.analyst}>
            <ul className={styles.analystList}>
                {list.map((item: any) => {
                    const { _id, title, core_symbol, time_frame, ts, summary } = item || {};
                    const timeFrameItem = timeFrameItemFun(time_frame);
                    const optionsItem = optionsItemFun(item, timeFrameItem);

                    const timer = ts ? dayjs(ts * 1000).format('HH:mm') : ts;
                    return (
                        <li
                            key={_id}
                            onClick={() => onClickItem(item)}
                            className={c(activeId === _id ? styles.active : null)}
                        >
                            <div className={styles.title}>
                                <h2>{title}</h2>
                                <span>{timer}</span>
                            </div>

                            <div className={styles.symbol}>
                                <span className={styles.symbolSign}>{core_symbol}</span>

                                <div className={styles.fluctuate} style={{ color: optionsItem?.color }}>
                                    <NextImage
                                        src={`/analyst/${optionsItem?.icon}.webp`}
                                        needPrefix
                                        width={18}
                                        height={18}
                                        alt={optionsItem?.icon}
                                    />
                                    <div
                                        className={styles.fName}
                                        dangerouslySetInnerHTML={{
                                            __html: `${intl
                                                .get(timeFrameItem?.title, { field: timeFrameItem?.title })
                                                .d(`${timeFrameItem?.title}内`)}
                                                ：${intl.get(optionsItem?.label)}`,
                                        }}
                                    />
                                </div>
                            </div>

                            <p className={styles.summary}>{summary}</p>
                        </li>
                    );
                })}
            </ul>

            {loader}
        </div>
    ) : (
        <>{loader}</>
    );
};

export default AnalystList;
