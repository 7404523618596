import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import MSearchItems from './m';
import PcSearchItems from './pc';
import type { FormDataProps } from './types';

interface IProps {
    symbolList?: any[];
    inSearch?: boolean;
    onChange: (a: FormDataProps) => void;
}

const SearchItems: React.FC<IProps> = (props) => {
    return (
        <TransferCom
            m={<MSearchItems {...props} />}
            ipad={<PcSearchItems {...props} />}
            pc={<PcSearchItems {...props} />}
        />
    );
};

export default SearchItems;
