import React, { ChangeEvent } from 'react';
import { Input } from 'antd';
import c from 'classnames';
import { debounce } from 'lodash';
import styles from './index.module.less';
import formUtils from '@/utils/form';
import intl from 'react-intl-universal';
const { pleaseEnter } = formUtils;

interface Props {
    onChange?: (a?: string) => void;
}

const TitleFilter: React.FC<Props> = ({ onChange }) => {
    const onInputChange = debounce((v: ChangeEvent<HTMLInputElement>) => {
        const {
            target: { value },
        } = v;
        onChange?.(value);
    }, 500);

    return (
        <div className={styles.titleFilter}>
            <Input
                className={styles.input}
                placeholder={pleaseEnter(intl.get('Analyze.Content').d('请输入内容'))}
                suffix={<span className={c(styles.suffix, 'iconfont', 'icon-sousuo')} />}
                onChange={onInputChange}
            />
        </div>
    );
};

export default TitleFilter;
