import React, { useMemo } from 'react';
import DropPicker from '../DropPicker';
import { SymbolDataProps } from '../../types';
import intl from 'react-intl-universal';
import styles from './index.module.less';

interface Props {
    list?: any[];
    inSearch?: boolean;
    onChange?: (a?: string[]) => void;
}

const SymbolFilter: React.FC<Props> = ({ list = [], onChange, inSearch }) => {
    const newList = useMemo(() => {
        return list.map((item) => {
            return { value: item.value, label: item.title };
        });
    }, [list]);

    const onChangeData = (symbol?: SymbolDataProps) => {
        const { symbol_type } = symbol || {};
        const symbolData = symbol_type && symbol_type !== 'All' ? [symbol_type] : undefined;
        onChange?.(symbolData);
    };

    return (
        <div className={styles.symbolFilter}>
            <DropPicker
                name={intl.get('Broker.Type').d('类型')}
                dropkey="symbol"
                left={true}
                list={newList}
                onChange={onChangeData}
                props={['symbol_type']}
                defaultValue={['All']}
                inSearch={inSearch}
            />
        </div>
    );
};

export default SymbolFilter;
