import React from 'react';

import TransferCom from '@/components/public/TransferCom';
import type { TrendDataProps } from '../../types';
import IpadTrendFilter from './ipad';
import MTrendFilter from './m';
import PcTrendFilter from './pc';

interface IProps {
    inSearch?: boolean;
    onChange?: (a?: TrendDataProps) => void;
}

const NavLineItem: React.FC<IProps> = (props) => {
    return (
        <TransferCom
            m={<MTrendFilter {...props} />}
            ipad={<IpadTrendFilter {...props} />}
            pc={<PcTrendFilter {...props} />}
        />
    );
};

export default NavLineItem;
