export const optionsSelectForA = [
    { label: 'Analyze.Rise', value: '2', color: '#12b858', icon: 'rise' },
    { label: 'Analyze.RestrictedRise', value: '1', color: '#12b858', icon: 'rise' },
    { label: 'Analyze.Shock', value: '0', color: '#666666', icon: 'steady' },
    { label: 'Analyze.RestrictedDecline', value: '-1', color: '#dd3333', icon: 'fall' },
    { label: 'Analyze.Decline', value: '-2', color: '#dd3333', icon: 'fall' },
];

export const optionsSelectForB = [
    { label: 'Analyze.Bullish', value: '1', color: '#12b858', icon: 'rise' },
    { label: 'Analyze.Shock', value: '0', color: '#666666', icon: 'steady' },
    { label: 'Analyze.Bearish', value: '-1', color: '#dd3333', icon: 'fall' },
];

// 日内视图: -2(下跌), -1(限制性下跌), 0(震荡), 1(限制性上涨), 2(上涨)
export const timeFrameSelect = [
    { label: 'Analyze.All', value: 'ALL', optionsSelect: [] },
    {
        label: `Analyze.IntradayView`,
        value: 'INTRADAY',
        title: `Analyze.CurrentDay`,
        prop: 'opinion_intraday',
        optionsSelect: optionsSelectForA,
    },
    {
        label: `Analyze.ShortTermView`,
        value: 'ST',
        title: 'Analyze.ShortTerm',
        prop: 'opinion_st',
        optionsSelect: optionsSelectForA,
    },
    {
        label: 'Analyze.MediumTermView',
        value: 'MT',
        title: 'Analyze.MediumTerm',
        prop: 'opinion_mt',
        optionsSelect: optionsSelectForB,
    },
];
