import React, { useState, useCallback, useMemo, useImperativeHandle, useEffect } from 'react';
import InfiniteScroller from 'react-infinite-scroller';
import LoadMoreForAnalyst from '../LoadMoreForAnalyst';
import request from '@/utils/axios';
import intl from 'react-intl-universal';

interface ExtraParams {
    [key: string]: any;
}

interface ResData {
    list: any[];
    total: number;
}

interface Props {
    useWindow?: boolean; // 监听滚动的元素 默认true   true = window  false = parentNode
    initialLoad?: boolean; // 是否自动加载第一页, 默认true   若node端加载第一页数据或者tab页 设置为false
    serviceData?: {
        // initialLoad = false 时传递，适合于node端加载第一页数据
        list: any[]; // 第一页数据
        hasMore: boolean; // 是否还有下一页
    };
    api: string; // 列表接口地址
    method?: 'get' | 'post'; // 请求方式
    extraParams?: ExtraParams; // 接口额外参数 默认含有分页参数 page 1 pageSize process.env.NEXT_PUBLIC_SETTING.pageSize
    transformData?: (res: any, t?: boolean) => ResData; // 处理返回的数据
    emptyText?: string; // 空状态文案
    onRef?: React.MutableRefObject<object | undefined>;
    renderList: (list: any[], loader?: React.ReactNode) => React.ReactNode;
    parentRef?: any;
}

const InfiniteScroll: React.FC<Props> = ({
    initialLoad = true,
    useWindow = true,
    serviceData = null,
    api = '',
    method = 'get',
    emptyText = intl.get('Common.EmptyText').d('未搜索到相关内容'),
    extraParams = {},
    transformData = (res) => ({
        list: res?.data?.list || [],
        total: res?.data?.total || res?.data?.count || 0,
    }),
    onRef,
    parentRef,
    renderList,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        setIsLoading(!initialLoad);
    }, [initialLoad]);

    const [isError, setIsError] = useState<boolean>(false);

    const { pageSize }: any = process.env.NEXT_PUBLIC_SETTING;
    const [params, setParams] = useState<any>({
        page: serviceData ? 1 : 0,
        pageSize: extraParams?.pageSize || pageSize,
        hasMore: serviceData ? serviceData.hasMore : initialLoad,
    });

    const [list, setList] = useState<any[]>(serviceData ? serviceData.list : []);

    useEffect(() => {
        if (serviceData) {
            setList(serviceData.list);
            setParams({
                page: 1,
                pageSize: extraParams?.pageSize || pageSize,
                hasMore: serviceData.hasMore,
            });
        }
    }, [extraParams?.pageSize, pageSize, serviceData]);

    const isEmpty = useMemo(() => {
        return params?.page === 1 && !list?.length && !isLoading;
    }, [isLoading, list?.length, params?.page]);

    const getList = useCallback(
        (page: number) => {
            if (!api) {
                return;
            }
            setIsLoading(true);
            setIsError(false);
            // delete extraParams.pageSize
            // delete extraParams.page_size
            delete extraParams.page;
            const apiParams = {
                page,
                pageSize: params.pageSize, // 兼容接口分页参数格式
                page_size: params.pageSize, // 兼容接口分页参数格式
                ...extraParams,
            };

            request[method](api, apiParams)
                .then((res: any) => {
                    const { state, stat } = res;
                    if (Number(state) === 1 || Number(stat) === 1) {
                        const { list: resList = [], total } = transformData(res, page === 1);
                        setList(page === 1 ? resList : [...list, ...resList]);
                        setParams({
                            ...params,
                            page,
                            pageSize: apiParams.pageSize,
                            hasMore: Math.ceil(total / apiParams.pageSize) > page,
                        });
                    } else {
                        setIsError(true);
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsError(true);
                    setIsLoading(false);
                });
        },
        [api, extraParams, list, method, params, transformData],
    );

    // 暴露方法给父级组件
    useImperativeHandle(onRef, () => {
        return {
            // 重置数据  配合tab页使用
            resetList: (isSetEmpty = false) => {
                if (isSetEmpty) {
                    // 仅清空数据
                    setList([]);
                    setParams({ ...params, page: 1, hasMore: false });
                } else {
                    // 第一页数据
                    setIsLoading(true);
                    setList([]);
                    setParams({ ...params, hasMore: false });
                    getList(1);
                }
            },
        };
    });

    useEffect(() => {
        setIsLoading(false);
    }, [serviceData]);

    const loadMoreData = useCallback(() => {
        if (isLoading || !params.hasMore || isError) {
            return;
        }
        getList(params.page + 1);
    }, [getList, isError, isLoading, params.hasMore, params.page]);

    const loader = useMemo(() => {
        return (
            <LoadMoreForAnalyst
                page={params.page}
                emptyText={emptyText}
                hasMore={params.hasMore}
                loading={isLoading}
                empty={isEmpty}
                error={isError}
                action={getList}
            />
        );
    }, [getList, emptyText, isEmpty, isError, isLoading, params.hasMore, params.page]);

    return (
        <InfiniteScroller
            initialLoad={initialLoad}
            pageStart={1}
            loadMore={loadMoreData}
            hasMore={params.hasMore}
            useWindow={useWindow}
            getScrollParent={() => parentRef?.current}
        >
            {renderList(list, loader)}
        </InfiniteScroller>
    );
};

export default InfiniteScroll;
