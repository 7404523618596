import React, { useMemo } from 'react';
import DropPicker from '../../DropPicker';
import type { TrendDataProps } from '../../../types';
import { timeFrameSelect } from '../../../config';
import { filters } from '@/utils/util';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props {
    inSearch?: boolean;
    onChange?: (a?: TrendDataProps) => void;
}

const SymbolFilter: React.FC<Props> = ({ onChange, inSearch }) => {
    const currentLocale = intl.getInitOptions().currentLocale;

    const onChangeData = (trend: TrendDataProps) => {
        const { time_frame } = trend || {};
        const symbolData = filters({
            ...trend,
            time_frame: time_frame && time_frame !== 'ALL' ? time_frame : undefined,
        });
        onChange?.(symbolData);
    };

    const formatList: any = (l: any[]) => {
        return l.map((item: any) => {
            const { label, alias, children = [] } = item;
            return { ...item, label: intl.get(label).d(alias), children: formatList(children || []) };
        });
    };

    const newList = useMemo(() => {
        return formatList(timeFrameSelect);
    }, [timeFrameSelect, currentLocale]);

    return (
        <div className={styles.symbolFilter}>
            <DropPicker
                name={intl.get('Analyze.Trend').d('趋势')}
                dropkey="trend"
                list={newList}
                onChange={onChangeData}
                props={['time_frame']}
                defaultValue={['ALL']}
                inSearch={inSearch}
            />
        </div>
    );
};

export default SymbolFilter;
