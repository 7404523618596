import React, { useState } from 'react';
import { Select } from 'antd';
import { NextImage } from '@/components';
import c from 'classnames';
import type { SelectProps } from 'antd';

import styles from './index.module.less';
import intl from 'react-intl-universal';

interface Props extends SelectProps {
    key?: string;
    onChange?: (a: string) => void;
}

const { Option } = Select;

const TrendFilter: React.FC<Props> = (props) => {
    const { onChange, value, placeholder, options, ...restProps } = props;
    // 是否打开下拉
    const [open, setOpen] = useState<boolean>(false);

    // 为空时
    const notFoundContent = (
        <div className={styles.nullData}>
            <NextImage src="/img/place/null_data.webp" width={128} height={102} alt="empty" />
            <p className={styles.nullTxt}>{intl.get('Analyze.NullTxt').d('暂无筛选项～')}</p>
        </div>
    );

    return (
        <div className={c(styles.customSelect, value ? styles.customSelectActive : null)}>
            <Select
                value={value}
                onChange={onChange}
                {...restProps}
                placeholder={placeholder || intl.get('Analyze.All').d('全部')}
                notFoundContent={notFoundContent}
                popupClassName={styles.popSelect}
                onDropdownVisibleChange={setOpen}
                suffixIcon={
                    <span className={c(styles.suffix, open ? styles.suffixOpen : null, 'iconfont', 'icon-jiantou')} />
                }
            >
                {options?.map((option) => (
                    <Option key={option.value} value={option.value} label={option.label}>
                        {intl.get(`${option.label}`)}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default TrendFilter;
