

import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import IpadTitleFilter from './ipad';
import MTitleFilter from './m';
import PcTitleFilter from './pc';

interface IProps {
    onChange?: (a?: string) => void
}

const TitleFilter: React.FC<IProps> = (props) => {
    return (
        <TransferCom
            m={<MTitleFilter {...props} />}
            ipad={<IpadTitleFilter {...props} />}
            pc={<PcTitleFilter {...props} />}
        />
    );
};

export default TitleFilter;
