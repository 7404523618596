import React from 'react';
import c from 'classnames';
import styles from './index.module.less';
import intl from 'react-intl-universal';

interface LoadMoreProp {
    page: number;
    loading?: boolean;
    hasMore?: boolean;
    empty?: boolean;
    isMobile?: boolean;
    error?: boolean;
    emptyText?: string;
    action?: (a: number) => void;
}

const LoadMoreForAnalyst: React.FC<LoadMoreProp> = (props) => {
    const {
        page,
        loading,
        hasMore,
        action,
        empty = false,
        error = false,
        emptyText = intl.get('Common.NoData').d('暂无数据'),
    } = props;

    if (error) {
        return (
            <div className={styles.error} onClick={action ? () => action(page) : () => console.log('no action...')}>
                {intl.get('Common.LoadFailedRetry').d('加载失败，点击重试')}
            </div>
        );
    } else if (empty) {
        return (
            <div className={c('load-empty', styles.empty)}>
                <span>{emptyText}</span>
            </div>
        );
    } else if (loading) {
        return (
            <div className={c(styles.loadMore, styles.loadMoreLoading)}>
                <span>{intl.get('Common.Loading').d('加载中')}</span>
                <ol>
                    <li />
                    <li />
                    <li />
                </ol>
            </div>
        );
    } else if (hasMore) {
        return (
            <div
                className={styles.pcLoadMore}
                onClick={() => {
                    action?.(page + 1);
                }}
            >
                {intl.get('Common.SeeMore').d('查看更多')}
            </div>
        );
    } else {
        return (
            <div className={c(styles.loadMore, styles.loadMoreLoaded)}>
                <span>{intl.get('Common.NoMore').d('没有更多了哦～')}</span>
            </div>
        );
    }
};

export default LoadMoreForAnalyst;
