import React from 'react';
import TransferCom from '@/components/public/TransferCom';
import IpadTimerFilter from './ipad';
import MTimerFilter from './m';
import PcTimerFilter from './pc';

interface IProps {
    inSearch?: boolean;
    onChange?: (a: string[] | null | undefined) => void;
}

const NavLineItem: React.FC<IProps> = (props) => {
    return (
        <TransferCom
            m={<MTimerFilter {...props} />}
            ipad={<IpadTimerFilter {...props} />}
            pc={<PcTimerFilter {...props} />}
        />
    );
};

export default NavLineItem;
