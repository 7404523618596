import intl from 'react-intl-universal';

export const optionsSelectForA = [
    { label: 'Analyze.Rise', value: '2', alias: '上涨' },
    { label: 'Analyze.RestrictedRise', value: '1', alias: '限制性上涨' },
    { label: 'Analyze.Shock', value: '0', alias: '震荡' },
    { label: 'Analyze.RestrictedDecline', value: '-1', alias: '限制性下跌' },
    { label: 'Analyze.Decline', value: '-2', alias: '下跌' },
];

export const optionsSelectForB = [
    { label: 'Analyze.Bullish', value: '1', alias: '看涨' },
    { label: 'Analyze.Shock', value: '0', alias: '震荡' },
    { label: 'Analyze.Bearish', value: '-1', alias: '看跌' },
];

// 日内视图: -2(下跌), -1(限制性下跌), 0(震荡), 1(限制性上涨), 2(上涨)
export const timeFrameSelect = [
    { label: 'Analyze.All', value: 'ALL', children: [], alias: '全部' },
    {
        label: `Analyze.IntradayView`,
        value: 'INTRADAY',
        title: `Analyze.CurrentDay`,
        prop: 'opinion_intraday',
        alias: '当日',
        children: optionsSelectForA,
    },
    {
        label: `Analyze.ShortTermView`,
        value: 'ST',
        title: 'Analyze.ShortTerm',
        prop: 'opinion_st',
        alias: '短期',
        children: optionsSelectForA,
    },
    {
        label: 'Analyze.MediumTermView',
        value: 'MT',
        title: 'Analyze.MediumTerm',
        prop: 'opinion_mt',
        alias: '中期',
        children: optionsSelectForB,
    },
];

/**
 * 筛选项
 */
export const timerFilters = [
    { label: 'Analyze.All', value: 'all', alias: '全部' },
    { label: 'Analyze.Today', value: 'today', alias: '今天' },
    { label: 'Analyze.SevenDay', value: 'sevenDay', alias: '近七天' },
    { label: 'Analyze.Timer', value: 'timer', alias: '时间' },
];

export const monthMap: any = {
    '1': 'Common.January',
    '2': 'Common.February',
    '3': 'Common.March',
    '4': 'Common.April',
    '5': 'Common.May',
    '6': 'Common.June',
    '7': 'Common.July',
    '8': 'Common.August',
    '9': 'Common.September',
    '10': 'Common.October',
    '11': 'Common.November',
    '12': 'Common.December',
};

export const dateConfig = {
    year: {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
    month: {
        format: (value: any) => intl.get(monthMap[value.getMonth() + 1]),
        caption: 'Mon',
        step: 1,
    },
    date: {
        format: (value: any) => `${value.getDate()}${intl.get('Common.Day').d('日')}`,
        caption: 'Day',
        step: 1,
    },
    hour: {
        format: 'hh',
        caption: 'Hour',
        step: 1,
    },
    minute: {
        format: 'mm',
        caption: 'Minute',
        step: 1,
    },
};
